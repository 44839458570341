/* Signup Form Container */
.signup-container {
  position: fixed;
  width: 100vw;
  height: 60vh;
  min-height: 655px;
  margin-top: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0 4px 90px rgba(0, 0, 0, 0.81);
  background: rgba(0, 0, 0, 0.65);
  overflow: visible;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  z-index: 2000;
  transform: translateY(125%);
  transition: transform 0.5s ease-out;
}

.signup-container:before {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: white;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    white 34%,
    white 89%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.12;
  filter: blur(0.5px);
  mix-blend-mode: hard-light;
}

.ai-msg .beta-signup {
  pointer-events: auto;
  max-width: 600px;
  margin: 0 auto 80px; /* Keep above footer */
  padding: 20px;
}

.signup-stage-container {
  position: absolute;
  width: 100vw;
  height: 100px;
  left: 50%;
  max-width: 1280px;
  text-align: center;
  margin: 0 auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  pointer-events: all;
  transform: translate(-50%, 0px);
  pointer-events: all;
  z-index: 5;
}

.signup-container-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  min-height: 800px;
  margin: 0 auto;
  margin-top: 100px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 400px;
  visibility: hidden;
  mask: linear-gradient(to top, transparent 5%, black 85%, transparent 100%);
  -webkit-mask: linear-gradient(
    to top,
    transparent 5%,
    black 85%,
    transparent 100%
  );
}

.signup-container-inner::-webkit-scrollbar {
  display: none;
}

.signup-form-container {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 525px;
  max-width: 1328px;
  margin: 0 auto;
  padding: 28px;
  overflow: hidden;
}

.form-slides {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  margin-top: 28px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.form-slides::-webkit-scrollbar {
  display: none;
}

/* Stage Blocks */
.signup-stage-blocks {
  position: absolute;
  left: 85px;
  top: 25px;
  right: 95px;
  height: 50px;
  display: flex;
  align-items: center;
  pointer-events: none;
  gap: 12px;
}

.stage-block {
  position: relative;
  height: 50px;
  background: rgba(255, 255, 255, 0.05);
  padding: 8px;
  padding-left: 18px;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 3px solid rgba(255, 255, 255, 0);
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 800ms ease-out 300ms,
    background 800ms ease-out 300ms;
  flex: 1;
}

.stage-block.active {
  background: rgba(0, 0, 0, 0.55);
  border-left: 3px solid rgb(77, 175, 255);
}

.stage-block .value {
  color: rgba(255, 255, 255, 0.55);
  font-size: 0.9rem;
  font-family: var(--font-roboto-light);
  font-weight: 300;
}

.stage-block .action {
  color: rgba(255, 255, 255, 0.25);
  font-size: 0.7rem;
  font-family: var(--font-roboto-light);
  font-weight: 200;
  margin-top: -4px;
}

.stage-block.active .value {
  color: rgba(255, 255, 255, 0.85);
  font-size: 0.9rem;
  font-family: var(--font-roboto-light);
  font-weight: 500;
}

.stage-block.active .action {
  color: rgba(255, 255, 255, 0.55);
  font-size: 0.7rem;
  font-family: var(--font-roboto-light);
  font-weight: 300;
}

.stage-block.active.done {
  color: #ffffff !important;
  background: rgba(48, 132, 200, 1);
  border-left: 3px solid rgba(255, 255, 255, 0.25);
  border-radius: 0 !important;
  transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.stage-block.done {
  color: rgba(255, 255, 255, 0.45);
  background: rgba(48, 132, 200, 0.55);
  border-left: 3px solid rgba(255, 255, 255, 0.25);
  border-radius: 0 !important;
  transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.stage-block.done:after {
  content: "";
  position: absolute;
  display: block;
  background-image: url("../assets/img/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  animation: checkBounce 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.stage-block.done .value {
  color: #ffffff !important;
}

.stage-block.done .action {
  color: rgba(255, 255, 255, 0.75);
  font-weight: 300;
}

.stage-block-main {
  display: flex;
  color: rgba(255, 255, 255, 0.75);
  background: transparent;
  font-size: 1.5rem;
  font-family: var(--font-roboto-light);
  font-weight: 300;
  padding: 0 20px;
  align-items: center;
  visibility: visible;
  opacity: 1;
  flex: 2;
  transition: opacity 800ms ease-out;
}

.stage-block-main.fade-out {
  opacity: 0;
}

.stage-block-main.fade-in {
  opacity: 1;
}

.stage-block-main.text-changing {
  opacity: 0;
}

/* Scoped Form Elements */
.beta-signup .form-slide {
  position: absolute;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 2.8rem;
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  left: 0;
  top: 0;
}

.beta-signup .form-slide.active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  display: grid;
  max-height: calc(100vh - 200px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.beta-signup .form-slide.active::-webkit-scrollbar {
  display: none;
}

.beta-signup .fs-form {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0;
  width: 100%;
  max-width: 1280px;
  align-items: start;
  text-align: left;
  padding: 0;
  padding-left: 48px;
  padding-top: 28px;
  padding-right: 58px;
  margin: 0 auto;
  margin-top: 0px;
  margin-right: 100px;
}

/* Left Column Container */
.beta-signup .form-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-template-columns: 1fr 255px;
  gap: 0;
  text-align: left;
}

.beta-signup .header-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  text-align: left;
  min-height: 68px;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding-left: 48px;
  padding-right: 58px;
}

.beta-signup .form-slide h2 {
  color: rgba(255, 255, 255, 0.55);
  font-size: 1.75rem;
  margin: 0;
  font-weight: 700;
  opacity: 0.95;
  letter-spacing: -0.02em;
  text-align: left;
}

.beta-signup .form-instruction {
  color: rgba(255, 255, 255, 0.35);
  font-size: 0.85rem;
  margin: 0;
  text-align: left;
  font-weight: 300;
}

.beta-signup .fs-form input {
  width: 100%;
  height: 100px;
  background: transparent;
  color: rgba(255, 255, 255, 0.95);
  font-size: 2.5rem !important;
  padding: 8px;
  padding-left: 18px;
  border: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 0;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}

.beta-signup .btn-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
  justify-self: end;
  width: 100%;
  height: 100px;
  margin: 0;
  background-color: rgba(48, 132, 200, 0.35);
  bottom: 0px;
}

.beta-signup .btn {
  display: flex;
  width: 300px;
  height: 100px;
  background: transparent;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  padding-left: 28px;
  padding-top: 28px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  pointer-events: all;
}

.beta-signup .btn:hover {
  background-color: rgba(48, 132, 200, 0.75);
}

.beta-signup .btn:active,
.beta-signup .btn:focus,
.beta-signup .btn:focus-within {
  background-color: rgba(48, 132, 200, 1);
}

.form-sumbitting {
  display: block;
  position: absolute;
  font-size: 0.75rem;
  font-family: var(--font-open-sans);
  font-weight: 300;
  color: rgba(255, 255, 255, 0.25);
  opacity: 0.35;
  left: 28px;
  bottom: 30px;
  pointer-events: none;
}

.form-submitting {
  display: block;
  position: absolute;
  font-size: 0.75rem;
  font-family: var(--font-open-sans);
  font-weight: 300;
  color: rgba(255, 255, 255, 0.25);
  opacity: 0.35;
  left: 22px;
  bottom: 30px;
  pointer-events: none;
}

.form-submitting i {
  font-size: 20px;
  margin-right: 8px;
  opacity: 0.6;
  color: rgba(255, 255, 255, 0.25) !important;
  transition: all 1.5s ease;
}

.form-submitting i.spin {
  transform: rotate(360deg);
}

.form-submitting.hide {
  display: none !important;
}

.beta-signup input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.01);
}

.beta-signup input::placeholder {
  color: rgba(255, 255, 255, 0.1) !important;
}

.beta-signup .form-group {
  margin-bottom: 2rem;
}

.beta-signup input.form-input.is-valid {
  border-color: #00c851;
  background: rgba(0, 200, 81, 0.05);
}

.beta-signup input.form-input.is-invalid {
  border-color: #ff4444;
  background: rgba(255, 68, 68, 0.05);
}

.beta-signup .form-feedback {
  margin-top: 0.75rem;
  font-size: 0.9rem;
  min-height: 24px;
  transition: all 0.3s ease;
}

.beta-signup .form-feedback.error {
  color: #ff4444;
}

.beta-signup .form-content .hide {
  display: none !important;
}

/* Container Transitions */
.start-container {
  transition: opacity 0.4s ease-out;
  opacity: 1;
  visibility: visible;
}

.signup-form-container {
  opacity: 0;
  transform: translateY(30px);
  transition:
    opacity 0.5s ease-out,
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
}

/* Input Control States */
.input-control {
  position: relative;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.input-control.focused .fs-floating-label {
  color: rgb(77, 175, 255);
  transform: translateY(-1.5rem) scale(0.85);
}

.input-control.focused .input-control-line {
  background: rgba(48, 132, 200, 0.25);
  transform: scaleX(1);
}

.input-control .input-control-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.1);
  transform: scaleX(0);
  transition: all 0.3s ease;
  transform-origin: left;
}

.fs-input:focus {
  outline: none;
}

.fs-input:focus + .fs-floating-label,
.fs-input:not(:placeholder-shown) + .fs-floating-label {
  transform: translateY(-1.5rem) scale(0.85);
  color: rgba(255, 255, 255, 0.6);
}

.form-slide.active.submitting {
  opacity: 0.5;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.hide-arrow[type="number"]::-webkit-inner-spin-button,
.hide-arrow[type="number"]::-webkit-outer-spin-button {
  appearance: none !important;
  -webkit-appearance: none !important;
  margin: 0;
}

/* Hide any form-specific spinners */
.fs-form .fs-spinner,
.fs-form .spinner,
.fs-form.submitting .fs-spinner,
.fs-form.submitting .spinner,
.fs-form.active.submitting .fs-spinner,
.fs-form.active.submitting .spinner,
.fs-form.submitting::after {
  display: none !important;
}

/* Open Button */
.open-button-container {
  position: relative;
  display: none;
  width: 100%;
  height: 28px;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: -40px;
}

.open-button {
  position: absolute;
  display: block;
  width: 80px;
  height: 28px;
  right: 18px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #070707;
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: margin-top 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  text-align: center;
  padding-right: 32px;
  padding-top: 4px;
  opacity: 0;
  pointer-events: all;
  cursor: pointer;
  z-index: 2000;
}

.chevron {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.chevron::after {
  content: "";
  position: absolute;
  top: 4%;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: solid 2px #383d40;
  border-right: solid 2px #383d40;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  margin: 5px 5px 5px 10px;
}

.open-button:hover .chevron::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top: solid 2px #ffffff;
  border-right: solid 2px #ffffff;
  top: 6px;
}

/* Close Button */
.close-button {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  right: 0px;
  background: transparent;
  margin: 25px;
  border: 0;
  opacity: 0.55;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  text-indent: -9999px;
  pointer-events: all;
  cursor: pointer;
}

.close-button:hover {
  background: #3084c8;
  opacity: 1;
}

.close-button:active,
.close-button:focus {
  background: rgb(77, 175, 255);
  outline: none;
}

.close-button:after,
.close-button:before {
  content: "" "";
  width: 55%;
  height: 2px;
  background: #fafafa;
  position: absolute;
  top: 48%;
  left: 22%;
  transform: rotate(-45deg);
  transition: 0.3s ease-out;
}

.close-button:after {
  transform: rotate(45deg);
  transition: 0.3s ease-out;
}

.close-button:hover:after,
.close-button:hover:before {
  transform: rotate(180deg);
}

/* Data Loader */
.loader {
  content: "";
  position: absolute;
  display: block;
  background: transparent;
  left: 25px;
  top: 25px;
  width: 50px;
  height: 50px;
  background-color: #3084c8;
}

/* Progress Bar */
.active.prog span.progress {
  position: absolute;
  display: block;
  border-bottom: 2px solid rgb(7, 169, 45);
  width: 33%;
  background: rgb(7, 169, 45);
  height: 2px;
  left: 0;
  bottom: 0;
  animation: border-progress 2s;
}

/* Animation Triggers */
body.show-signup .open-button {
  visibility: hidden;
  opacity: 0;
}

body.hide-signup .open-button {
  visibility: visible;
  opacity: 1;
}

body.loading .loader {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  background: transparent;
  left: 25px;
  border-radius: 100%;
  -webkit-perspective: 120px;
  -moz-perspective: 120px;
  -ms-perspective: 120px;
  perspective: 120px;
}

body.loading .loader:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 50px;
  height: 50px;
  background-color: #3084c8;
  animation: flip 1.5s infinite;
}

body.show-ai .open-button-container {
  display: block;
}

body.scrolled .signup-container,
body.show-signup .signup-container {
  transform: translateY(0);
}

body.hide-signup .signup-container {
  transform: translateY(125%);
}

body.signup.register .stage-block {
  visibility: visible;
  opacity: 1;
}

body.register .signup-container-inner {
  visibility: visible;
}

body.register .signup-form-container,
body.register .form-slides {
  visibility: visible;
}

body.signup.register .start-container {
  display: none;
  visibility: hidden;
  opacity: 0;
}

body.register .signup-form-container {
  display: block;
  visibility: visible;
  opacity: 1;
}

body.signup.register .start-container {
  opacity: 0;
  visibility: hidden;
  display: none;
  transition:
    opacity 0.4s ease-out,
    visibility 0s linear 0.4s;
}

body.signup.register .signup-form-container {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  display: block;
  transition:
    opacity 0.5s ease-out 0.2s,
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.2s,
    visibility 0s linear;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .loader {
    left: 15px;
    top: 15px;
    width: 28px;
    height: 28px;
  }

  body.loading .loader {
    width: 28px;
    height: 28px;
    left: 15px;
  }

  body.loading .loader:before {
    width: 28px;
    height: 28px;
  }

  .close-button {
    width: 28px;
    height: 28px;
    margin: 15px;
  }

  .beta-signup .signup-form-container {
    max-width: 100%;
    margin: 1rem;
    padding: 2rem;
  }

  .beta-signup .form-slide h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .beta-signup .form-input {
    padding: 1.25rem;
    font-size: 1.1rem;
  }

  .beta-signup .btn {
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 980px) {
  .beta-signup .form-slide {
    padding: 2rem;
  }

  .beta-signup .fs-form {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .beta-signup .btn {
    width: 100%;
  }
}

/* Animation Sequences */
@keyframes border-progress {
  0% {
    width: 0%;
  }
  100% {
    width: 33%;
  }
}

@keyframes flip {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}

@keyframes checkBounce {
  0% {
    transform: translateY(-50%) scale(0);
  }
  30% {
    transform: translateY(-50%) scale(1.4);
  }
  80% {
    transform: translateY(-50%) scale(0.9);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}

/* Loading State */
body .fs-form {
  pointer-events: auto;
}

body.loading .fs-form {
  pointer-events: none !important;
  opacity: 0.5;
  transition: opacity 300ms ease;
}

body.loading .fs-form * {
  pointer-events: none !important;
}
