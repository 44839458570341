/* Core Imports */
@import "core/variables.css";
@import "core/typography.css";
@import "core/grid.css";
@import "core/reset.css";
@import "gradient.css";
@import "splash.css";
@import "signup.css";
@import "legal.css";

/* Disable Scolling */
body {
  height: 100%;
  min-height: calc(100vh + 100px);
  overflow: hidden;
  overflow-x: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body.ready {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body.deselect * {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* App Container Styles */
.app {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 980px;
  margin: 0 auto;
  padding: 0;
  transition: height 300ms ease-in 1000ms;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.app.loaded {
  height: 100vh;
  min-height: 100vh;
}

.app .app-inner {
  width: 100vw;
  height: 100vh;
}

/* Main Container Styles */
.container {
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 800px;
  margin: 0 auto;
  text-align: center;
  background: transparent;
  z-index: 11;
  transition: scale 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 300ms;
  scale: 0;
}

.app.loaded .container {
  scale: 1;
  transform: translateZ(0);
  will-change: transform;
  transition-delay: 100ms;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

/* Overlay and Logo Styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/darkstripe.png");
  background-repeat: repeat;
  opacity: 1;
  transition: opacity 600ms ease-out 100ms;
  z-index: 5;
}

img.logo {
  position: absolute;
  width: 180px;
  height: auto;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -135px);
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.25));
  z-index: 10;
}

/* Card Component Styles */
.card {
  position: absolute;
  z-index: 10;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  width: 90%;
  max-width: 600px;
  min-width: 290px;
  padding: 48px;
  background: rgba(0, 0, 0, 0.85);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0 4px 90px rgba(0, 0, 0, 0.81);
  background: rgba(0, 0, 0, 0.65);
  overflow: visible;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  will-change: transform;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.card:before {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: white;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    white 34%,
    white 89%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.12;
  filter: blur(0.5px);
  mix-blend-mode: hard-light;
}

/* Card Content Styles */
.card .noise {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.1;
}

.card .content-box {
  position: relative;
  color: #242424;
  z-index: 2;
  text-shadow: -3px 0px 2px rgba(0, 0, 0, 0.1);
}

.card h1 {
  font-family: var(--font-opensans);
  font-size: 3.5rem;
  margin-bottom: 36px;
  margin-top: 20px;
  color: #ffffff;
  text-align: center;
}

.card p {
  font-family: var(--font-roboto);
  font-size: 1.25rem;
  line-height: 1.5;
  text-align: center;
  color: rgba(255, 255, 255, 0.65);
  padding-bottom: 28px;
}

.card .line {
  width: 55px;
  background: rgba(255, 255, 255, 0.15);
  margin: 0px auto;
  margin-top: 48px;
  margin-bottom: 18px;
  height: 0.65px;
}

.card .soon,
.card .help {
  font-family: var(--font-roboto);
  color: rgba(255, 255, 255, 0.25);
  font-size: 0.85rem;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 28px;
  max-width: 335px;
  margin: 0 auto;
}

/* AI Feature Styles */
.ai {
  position: fixed;
  top: -20px;
  right: 0;
  font-family: var(--font-opensans);
  font-size: 7.85rem;
  text-align: center;
  padding: 0px;
  padding-right: 28px;
  color: rgba(255, 255, 255, 0.25);
  opacity: 0;
  transition: opacity 1.25s ease;
  cursor: pointer;
  z-index: 400;
  scale: 0.75;
}

.ai .ai-msg {
  position: absolute;
  top: 148px;
  right: 32px;
  font-size: 1.05rem;
  font-family: var(--font-raleway);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.65);
  transition: opacity 500ms ease;
  transition-delay: 300ms;
  font-weight: 200;
  opacity: 0;
}

.ai:hover,
.ai:hover .ai-msg {
  opacity: 1;
}

/* Button Styles */
.btn {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: darken(var(--primary-color), 10%);
}

button {
  pointer-events: all;
  cursor: pointer;
}

button.disabled,
button:disabled {
  pointer-events: none !important;
  cursor: default !important;
}

/* Footer Styles */
.footer {
  position: relative;
  width: 100%;
  height: 100px;
  min-width: 100vw;
  text-align: center;
  margin: 0 auto;
  background: #070707;
  visibility: hidden;
  transition: height 300ms ease-in 300ms;
  z-index: 3000;
  pointer-events: all;
}

.footer-content {
  position: relative;
  width: 100%;
  height: 100px;
  max-width: 1280px;
  margin: 0 auto;
  bottom: 0px;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 2rem;
  align-items: center;
  opacity: 1;
  pointer-events: all;
  background: #070707;
  z-index: 3000;
}

.logo-container {
  position: relative;
  display: none;
  width: 100%;
  height: 100px;
  max-width: 1280px;
  margin: 0 auto;
}

.footer .copyright-container,
.footer .social-container {
  position: relative;
  opacity: 0;
  transition: opacity 300ms ease-in 1500ms;
}

.app.loaded .footer {
  height: 100px;
  visibility: visible;
}

.app.loaded .footer .copyright-container,
.app.loaded .footer .social-container {
  opacity: 1;
}

/* Copyright Styles */
.copyright-container {
  position: relative;
  padding-left: 32px;
  height: 48px;
}

.copyright,
.copyright-container p,
.copyright-container span {
  margin: 0 auto;
  text-align: left;
  font-family: var(--font-opensans);
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.35);
}

.legal-links {
  position: absolute;
  bottom: 6px;
  left: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  font-weight: 300;
  opacity: 0.25;
  cursor: pointer;
  transition: opacity 300ms ease;
}

.legal-links i {
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid #cccccc;
  margin-left: 12px;
  margin-right: 2px;
}

.legal-links:hover {
  opacity: 1;
}

.legal-links span {
  color: #ffffff;
  cursor: pointer;
  transition: opacity 300ms ease;
  pointer-events: all;
  cursor: pointer;
}

.legal-links span:hover {
  opacity: 0.75;
  color: #4a90e2;
}

/* Social Media Styles */
.social-container {
  grid-template-columns: repeat(auto-fit, minmax(40px, auto));
  gap: 0.5rem;
  justify-content: end;
  padding-right: 32px;
}

div.social {
  display: inline-block;
  padding: 4px;
  margin-right: 8px;
  pointer-events: all;
  cursor: pointer;
}

img.social {
  transition: opacity 250ms ease-in-out;
  opacity: 0.35;
  pointer-events: all;
  cursor: pointer;
}

.social a:hover img,
.social a:focus img,
.social a:active img {
  opacity: 0.85;
}

/* Add Media Queries */
@media (max-width: 768px) {
  .app.loaded .footer {
    height: 155px;
    transition: none !important;
  }

  .footer-content {
    gap: 12px;
    grid-template-columns: 1fr;
    text-align: center;
    bottom: 40px;
  }

  .copyright-container {
    margin: 0 auto;
    margin-top: 75px;
    padding-bottom: 0px;
    padding-left: 28px;
    padding-right: 28px;
    text-align: center;
  }

  .copyright,
  .copyright-container p,
  .copyright-container span {
    margin: 0 auto;
    text-align: center;
  }

  .legal-links {
    position: relative;
    width: 215px;
    left: auto;
    margin: 0 auto;
    margin-top: 18px;
    align-items: center;
    text-align: center;
  }

  .social-container {
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 28px;
    padding-right: 0px;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  :root {
    --card-padding: 24px;
    --logo-width: 180px;
  }

  .card h1 {
    font-size: 2.75rem;
  }

  .card p {
    font-size: 1.12rem;
  }

  img.logo {
    width: 180px;
    transform: translate(-50%, -135px);
  }

  .ai {
    top: -30px;
    padding-right: 18px;
    scale: 0.55;
  }

  .ai .ai-msg {
    right: 16px;
    font-size: 1.28rem;
    transition-delay: 400ms;
    font-weight: 300;
    opacity: 0;
  }

  .copyright-container {
    margin-top: 55px !important;
  }
}

/* Adjust for height */
@media (max-height: 1040px) {
  .card {
    top: calc(40% + 90px);
  }

  img.logo {
    width: 155px;
    transform: translate(-50%, -115px);
  }
}

/* Resets (hacks) for cursor issues */

.app.loaded .social img,
.app.loaded .social a,
.app.loaded .social a:hover,
.app.loaded .social a:focus,
.app.loaded .social a:active,
.app.loaded div.social:hover,
.app.loaded div.social:focus,
.app.loaded div.social:active,
.app.loaded img.social,
.app.loaded a.text-link,
.app.loaded a.text-link:hover,
.app.loaded a.text-link:focus,
.app.loaded a.text-link:active,
.app.loaded .legal-links,
.app.loaded .legal-links span,
.app.loaded .legal-links span:hover,
.app.loaded .legal-links span:focus,
.app.loaded .legal-links span:active,
.app.loaded .legal-download,
.app.loaded .legal-docs-tabs,
.app.loaded .legal-docs-tabs span,
.app.loaded .legal-docs-tabs a,
.app.loaded .legal-docs-tabs a:hover,
.app.loaded .legal-docs-tabs a:focus,
.app.loaded .legal-docs-tabs a:active,
.app.loaded button,
.app.loaded button:hover,
.app.loaded button:focus,
.app.loaded button:active {
  pointer-events: all !important;
  cursor: pointer !important;
}

.disabled,
.disabled:hover,
.disabled:focus,
.disabled:active,
*:disabled {
  pointer-events: none !important;
  cursor: default !important;
}
