/* App Container Styles */
.bg {
  position: absolute;
  /* background-image: url("../assets/img/splash-bg.svg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed; */
  background: linear-gradient(to bottom, #020107 0%, #201b46 100%);
  top: 110vh;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease-in 800ms;
  transition-delay: 500ms;
  z-index: -10;
}

.fs-logo {
  position: absolute;
  width: 200px;
  height: auto;
  top: 32px;
  left: 20px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 800ms ease-in 800ms;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.25));
  z-index: 10;
}

.splash-container {
  position: absolute;
  top: 110vh;
  left: 0;
  width: 100%;
  height: 0px;
  opacity: 0;
  transition: opacity 300ms ease-in 800ms;
  z-index: -10;
}

.splash-inner {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  width: 120vw;
  height: 100%;
  z-index: 1001;
}

.mountains {
  position: absolute;
  background-image: url("../assets/img/splash-mountain.svg");
  background-position: center bottom;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  bottom: -10%;
  left: 0;
  width: calc(105vw + 5.5vw);
  height: 105vh;
  min-width: 105vw;
  top: 100vh;
  transition: top 1800ms cubic-bezier(0.385, 0.035, 0.515, 0.86) 800ms;
  z-index: 1002;
}

.valley {
  position: absolute;
  background-image: url("../assets/img/splash-valley.svg");
  background-position: center bottom;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  left: 0;
  width: 105vw;
  height: 105vh;
  min-width: 105vw;
  top: 100vh;
  transition: top 1200ms cubic-bezier(0.385, 0.035, 0.515, 0.86) 900ms;
  z-index: 1003;
}

.textblock {
  position: absolute;
  width: 100vw;
  font-family: var(--font-roboto);
  letter-spacing: -0.025em;
  color: rgba(255, 255, 255, 0.95);
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.45));
  pointer-events: auto;
  text-align: center;
  text-transform: none;
  margin: 0 auto;
  top: 30%;
  font-size: 11vw;
  font-weight: 800;
  line-height: 1;
  margin-top: 100vh;
  transition: margin-top 1200ms cubic-bezier(0.385, 0.035, 0.515, 0.86) 2000ms;
  z-index: 1004;
}

.trees {
  position: absolute;
  background-image: url("../assets/img/splash-trees.svg");
  background-position: center bottom;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  left: 0;
  width: 105vw;
  height: 105vh;
  min-width: 105vw;
  top: 100vh;
  margin-bottom: -55px;
  transition: top 800ms cubic-bezier(0.385, 0.035, 0.515, 0.86) 800ms;
  z-index: 1005;
}

.dude {
  position: absolute;
  background-image: url("../assets/img/splash-dude.svg");
  background-position: center bottom;
  background-size: auto 75%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  left: 0;
  width: calc(105vw + 2.25vw);
  height: 100vh;
  min-width: 105vw;
  overflow: hidden;
  top: 100vh;
  transition: top 800ms cubic-bezier(0.385, 0.035, 0.515, 0.86) 500ms;
  z-index: 1006;
}

.scrollme {
  overflow: hidden;
}

.ai-panel {
  position: absolute;
  margin-top: 100vh;
  width: 100vw;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.5);
}

/* About Container */
.about-container,
.start-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 655px;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 100px;
  padding-top: 82px;
  padding-bottom: 480px;
  text-align: left;
  mask: linear-gradient(to top, transparent 5%, black 85%, transparent 100%);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  transform: translateY(100%);
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  z-index: 1;
}

.about-container h2,
.more-about h2,
.start-container h2 {
  position: relative;
  width: 70%;
  font-size: 2.8rem;
  font-family: var(--font-roboto);
  color: rgba(255, 255, 255, 0.95);
  line-height: 1.35;
  font-weight: 600;
  transition: opacity 800ms ease-in;
  transition-delay: 300ms;
  opacity: 0;
}

.about-container p,
.more-about p,
.start-container p {
  font-size: 1.5rem;
  font-family: var(--font-roboto-light);
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.5;
  font-weight: 200;
  padding-bottom: 28px;
  transition: opacity 800ms ease-in;
  transition-delay: 300ms;
  opacity: 0;
}

.about-container.active {
  transform: translateY(0);
  opacity: 1;
}

.about-container.active h2,
.about-container.active p {
  opacity: 1;
}

.about-container p .highlight,
.more-about p .highlight {
  color: rgb(77, 175, 255);
}

/* About Section (intro text*/
.about-main {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 2rem;
  margin-bottom: 0;
  align-items: end;
}

.about-main h2 {
  width: 70%;
  font-size: 2.8rem;
  font-family: var(--font-roboto);
  color: rgba(255, 255, 255, 0.95);
  line-height: 1.35;
  font-weight: 600;
  transition: opacity 800ms ease-in;
  transition-delay: 300ms;
  opacity: 0;
  margin: 0;
  padding: 0;
}

.about-main button {
  position: absolute;
  display: block;
  width: 185px;
  height: 50px;
  font-size: 1.25rem;
  font-weight: 300;
  background: transparent;
  border-radius: 1px;
  border: 2px solid rgba(255, 255, 255, 0.65);
  color: rgba(255, 255, 255, 0.8);
  padding: 12px 6px;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  right: 8px;
  top: calc(100% - 58px);
  pointer-events: all;
  cursor: pointer;
  z-index: 100;
}

.about-main button:hover {
  color: #ffffff;
  background: #3084c8;
  border: 2px solid rgba(48, 132, 200, 1);
  opacity: 1;
}

.about-main button:active,
.about-main button:focus {
  background: rgb(77, 175, 255);
  border: 2px solid rgba(77, 175, 255, 1);
  outline: none;
}

.about-container p:first-of-type {
  margin-top: 2.5rem;
}

/* Learn More Link */
.action-container {
  display: block;
  margin-top: 28px;
}

.text-link {
  position: relative;
  display: inline-block;
  color: rgba(255, 255, 255, 0.85);
  font-family: var(--font-roboto-light);
  font-size: 1.1rem;
  text-decoration: none;
  padding-right: 40px;
  transition: all 0.3s ease-in-out;
  pointer-events: all;
  cursor: pointer;
}

p a.text-link {
  margin-right: 0 !important;
  padding-right: 0 !important;
  font-size: 1.5rem;
  font-family: var(--font-roboto-light);
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
  pointer-events: all;
  cursor: pointer;
}

.text-link:hover,
p a.text-link:hover {
  color: rgb(77, 175, 255);
}

.text-link i {
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 8px;
  bottom: 6px;
  right: 26px;
  border: 2px solid rgba(255, 255, 255, 0.85);
  border-width: 0 3px 3px 0px;
  display: inline-block;
  animation: transform 0.3s ease-in-out;
  animation-direction: reverse;
  transition: all 0.3s ease-in-out;
  transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
}

.text-link:hover i {
  bottom: 7.45px;
  right: 24px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Other Content Sections */
.more-about {
  display: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition:
    opacity 600ms ease-out,
    transform 600ms ease-out;
  margin-top: 68px;
  padding-top: 72px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Start Container */
.start-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 655px;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 100px;
  padding-top: 82px;
  padding-bottom: 400px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  visibility: hidden;
  opacity: 0;
  transform: translateY(75px);
  transition:
    visibility 0ms linear 0ms,
    opacity 800ms ease-out,
    transform 800ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow: hidden;
  overflow-y: auto;
}

.start-container h2,
.start-container p,
.start-container .begin-button {
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 800ms ease-in 300ms,
    transform 800ms ease-in 300ms;
}

.start-container.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.start-container.active h2,
.start-container.active p,
.start-container.active .begin-button {
  opacity: 1;
  transform: translateY(0);
}

.start-container .begin-button {
  position: absolute;
  width: 190px;
  height: 50px;
  font-size: 1.25rem;
  font-weight: 300;
  background: #3084c8;
  border-radius: 1px;
  border: 2px solid rgba(48, 132, 200, 1);
  color: rgba(255, 255, 255, 0.8);
  padding: 12px 6px;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 800ms;
  opacity: 0;
  right: 8px;
  top: calc(100% - 58px);
  pointer-events: all;
  cursor: pointer !important;
  z-index: 100;
}

.start-container .begin-button:hover {
  color: #ffffff;
  background: rgb(77, 175, 255);
  border: 2px solid rgba(77, 175, 255, 1);
}

.start-container .begin-button:active,
.start-container .begin-button:focus {
  color: #ffffff;
  background: rgb(77, 175, 255);
  outline: none;
}

.start-container.active .begin-button {
  opacity: 1;
}

.start-container .text-link {
  opacity: 0;
  transition: opacity 800ms ease-in 800ms;
  margin-top: 18px;
}

.start-container.active .text-link {
  opacity: 1;
}

.start-container p:first-of-type {
  margin-top: 2.5rem;
}

/* Word Rotation Animation */
.word-rotate {
  display: inline-block;
  position: relative;
  text-align: left;
}

.word-rotate-items {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;
}

.word-rotate-items span {
  display: inline-block;
  color: rgb(77, 175, 255);
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.5s ease-out;
}

.word-rotate-items span:first-child {
  color: #ffffff;
}

.word-rotate-items span.active {
  opacity: 1;
  position: relative;
}

/* Resize Handle */
.resize-handle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  cursor: ns-resize;
  opacity: 0;
  z-index: 2001;
}

.resize-handle:hover {
  opacity: 0.1;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), transparent);
}

/* Animation Triggers */
body.show-ai .overlay {
  opacity: 0;
}

body.show-ai .ai {
  transition-delay: 1400ms;
  opacity: 0;
}

body.show-ai .bg {
  top: 0;
  height: 100%;
  opacity: 0.35;
  visibility: visible;
  z-index: 1000;
}

body.show-ai .gradient-bg {
  z-index: unset;
  min-height: 100vh;
}

body.show-ai .splash-container {
  position: fixed;
  top: 0;
  height: 100%;
  opacity: 1;
  z-index: 1000;
}

body.show-ai .logo-container {
  visibility: visible;
  display: block;
  z-index: 91000;
}

body.show-ai .footer-content {
  height: 180px;
  transition:
    opacity 1200ms ease-in 100ms,
    bottom 800ms ease-in 0ms;
  opacity: 0;
}

body.show-ai.scrolled .footer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

body.show-ai.scrolled .footer-content {
  position: fixed;
  opacity: 1;
}

body.show-ai.scrolled .fs-logo {
  visibility: visible;
  opacity: 1;
}

body.show-ai.scrolled .scrollme {
  overflow-y: scroll;
}

body.show-ai .textblock {
  margin-top: 0;
}

body.show-ai .mountains {
  top: 0;
}

body.show-ai .valley {
  top: 10%;
}

body.show-ai .trees {
  top: 10%;
}

body.show-ai .dude {
  top: 10%;
}

body.show-ai .card {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

body.show-ai .app.loaded .footer {
  position: relative;
  height: 225px;
  padding-top: 18px;
  z-index: 2100;
}

body.resizing {
  cursor: ns-resize;
  user-select: none;
}

body.signup .about-container {
  display: none;
}

body.resizing .signup-container {
  transition: none;
}

body.signup .start-container {
  display: block;
  visibility: visible;
}

body.show-ai.read-more .more-about {
  display: block;
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

body.show-ai.read-more .text-link {
  padding-right: 18px;
}

/* Responsive Media Queries */
@media (max-width: 1220px) {
  .stage-block {
    display: none;
  }

  .stage-block-main {
    display: flex !important;
    width: 100%;
  }
}

@media (max-width: 1080px) {
  .about-main h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 980px) {
  .about-main h2 {
    font-size: 2.25rem;
  }
}

@media (max-width: 880px) {
  .about-main {
    grid-template-columns: 1fr;
    gap: 1rem;
    align-items: start;
    margin-bottom: 128px;
  }

  .about-main h2 {
    width: 100%;
    font-size: 2rem;
  }

  .about-main button {
    margin-top: 90px;
    right: auto;
    left: 0;
  }
}

@media (max-width: 768px) {
  .container {
    min-height: 100vh;
  }

  .mountains {
    width: calc(105vw + 15.5vw);
  }

  .textblock {
    font-size: 15vw;
  }

  .dude {
    width: calc(105vw + 8.5vw);
  }

  .fs-logo {
    position: relative;
    width: 185px;
    height: auto;
    top: 28px;
    left: -10px;
  }

  .stage-block-main {
    padding: 0px !important;
    margin-top: -42px;
    margin-left: -18px;
  }

  .about-container p:first-of-type,
  .start-container p:last-of-type {
    margin-top: 2rem;
  }

  .about-container,
  .start-container {
    padding: 48px 28px 28px;
    padding-bottom: 400px;
  }

  .about-container::before,
  .start-container::before {
    height: 125px;
  }

  .about-container p,
  .start-container p {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .about-main {
    margin-bottom: 100px;
  }

  .about-main h2 {
    font-size: 1.85rem;
  }

  .about-main button {
    margin-top: 80px;
    height: 48px;
    font-size: 1.1rem;
    transition: top none;
  }
}

@media (max-width: 480px) {
  .stage-block-main {
    font-size: 0.85rem;
    margin-left: -28px;
  }

  .about-main h2 {
    font-size: 1.72rem;
  }

  .about-container p,
  .more-about p {
    font-size: 1rem;
  }

  .fs-logo {
    width: 165px !important;
    top: 18px !important;
    left: 0px !important;
  }

  .copyright-container {
    margin-top: 85px !important;
  }
}

@-webkit-keyframes bounceOutDown {
  0% {
    margin-top: 0;
  }
  20% {
    opacity: 1;
    margin-top: -20px;
  }
  99% {
    opacity: 0.01;
    margin-top: 100vh;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    margin-top: 0;
    z-index: -10;
  }
}

@keyframes bounceOutDown {
  0% {
    margin-top: 0;
  }
  20% {
    opacity: 1;
    margin-top: -20px;
  }
  99% {
    opacity: 0.01;
    margin-top: 100vh;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    margin-top: 0;
    z-index: -10;
  }
}
